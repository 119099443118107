<template>
  <div class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <main>
      <div class="main_content align-items-center">
        <form method="post" @submit.prevent="addPermission" data-vv-scope="addPermissionForm">
          <div class="row justify-content-between mb_40">
            <div class="col-md-12">
              <div class="heading">
                <h2>User Roles/Permissions</h2>
                <h3>PBE Management Dashboard</h3>
              </div>
            </div>
          </div>
          <div class="tabs bg-white mb_50">
            <div class="permission_wrap">
              <h4 class="font_size_20 mx-auto text-left mb_20">
                Role Information
              </h4>
              <div class="row">
                <div class="col-lg-5">
                  <div class="form-group">
                    <label for="disabledTextInput" class="form-label">Role Name <span>*</span></label>
                    <input type="text" id="disabledTextInput" class="form-control" placeholder="Enter new role name"
                      name="user_role" v-model="role_data.display_name" v-validate="'required'" />
                    <small class="error text-danger" v-show="errors.has('addPermissionForm.user_role')">
                      {{ errors.first("addPermissionForm.user_role") }}
                    </small>
                  </div>
                </div>
                <input type="hidden" v-model="role_data.u_id" />
                <div class="col-lg-5">
                  <div class="form-group">
                    <label for="disabledTextInput" class="form-label">Description<span> *</span></label>
                    <input type="text" id="disabledTextInput" class="form-control" placeholder="Enter role description"
                      name="Description" v-model="role_data.description" v-validate="'required'" />
                    <small class="error text-danger" v-show="errors.has('addPermissionForm.Description')">
                      {{ errors.first("addPermissionForm.Description") }}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tabs bg-white">
            <div class="permission_wrap">
              <h4 class="font_size_20 mx-auto text-left mb_0">
                Permissions <span style="color: red">*</span>
              </h4>
              <p class="error text-danger" v-show="permission_error">
                Permission is required.
              </p>

              <div class="permission_table mt-0 add_p">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="permission_table table-responsive mt_40">
                      <h5>Admin Center</h5>
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">View</th>
                            <th scope="col">Edit</th>
                            <th scope="col">Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>User Roles</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="ac_ur" value="1" v-model="role_data.permission" id="ac_ur_v"
                                  class="form-check-input" />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="ac_ur" id="ac_ur_e" class="form-check-input" value="3"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="ac_ur" id="ac_ur_e" class="form-check-input" value="4"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Users</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="ac_u" id="ac_u_v" class="form-check-input" value="5"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="ac_u" id="ac_u_e" class="form-check-input" value="6"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="ac_u" id="ac_u_d" class="form-check-input" value="7"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Clients</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="ac_c" id="ac_c_v" class="form-check-input" value="8"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="ac_c" id="ac_c_e" class="form-check-input" value="9"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="ac_c" id="ac_c_e" class="form-check-input" value="10"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Email Templates</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="ac_et" id="ac_et_v" class="form-check-input" value="11"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="ac_et" id="ac_et_e" class="form-check-input" value="12"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="ac_c" id="ac_c_d" class="form-check-input" value="13"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Send Notification</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="ac_sn" id="ac_sn_v" class="form-check-input" value="14"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                            <!-- <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="ac_sn"
                                  id="ac_sn_e"
                                  class="form-check-input"
                                  value="15"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td> -->
                          </tr>
                          <tr>
                            <td>Audit Log Viewer</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="ac_alv" id="ac_alv_v" class="form-check-input" value="17"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                            <!-- <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="ac_alv"
                                  id="ac_alv_e"
                                  class="form-check-input"
                                  value="18"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>                             -->
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="permission_table table-responsive mt_40">
                      <h5>Dashboard Pages</h5>
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">View</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Program Overview</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="dp_po" id="dp_po_v" class="form-check-input" value="23"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Self-Assessment</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="dp_sa" id="dp_sa_v" class="form-check-input" value="24"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Ergo Eval Activity</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="dp_eea" id="dp_eea_v" class="form-check-input" value="25"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Follow-Up Feedback</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="dp_fuf" id="dp_fuf_v" class="form-check-input" value="26"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Employee Search</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="dp_es" id="dp_es_v" class="form-check-input" value="27"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <!-- <tr>
                            <td>Program Manager Help Center</td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="dp_pmhc"
                                  id="dp_pmhc_v"
                                  class="form-check-input"
                                  value="28"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                          </tr> -->
                          <tr>
                            <td>Employee Dashboard</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="dp_ed" id="dp_ed_v" class="form-check-input" value="31"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td><strong>Help Center</strong></td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="dp_ehc" id="dp_ehc_v" class="form-check-input" value="32"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td class="pl-30">Help Center - General Ergo</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="dp_ehc" id="dp_ehc_v" class="form-check-input" value="112"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td class="pl-30">Help Center - My Reports</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="dp_ehc" id="dp_ehc_v" class="form-check-input" value="113"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td class="pl-30">Help Center - My Data</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="dp_ehc" id="dp_ehc_v" class="form-check-input" value="114"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td class="pl-30">Help Center - Additional Support</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="dp_ehc" id="dp_ehc_v" class="form-check-input" value="115"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td class="pl-30">Help Center - Dashboard Tutorials</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="dp_ehc" id="dp_ehc_v" class="form-check-input" value="116"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Custom Search</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="dp_cs" id="dp_cs_v" class="form-check-input" value="102"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>



                    <div class="permission_table table-responsive mt_40">
                      <h5>PBE Management Dashboard Pages</h5>
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">View</th>
                          </tr>
                        </thead>
                        <tbody>
                          <!-- <tr>
                            <td>Evaluator</td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="dp_e"
                                  id="dp_e_v"
                                  class="form-check-input"
                                  value="97"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                          </tr> -->
                          <tr>
                            <td>Client Activity</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="dp_ca" id="dp_ca_v" class="form-check-input" value="29"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Client Managers</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="dp_cm" id="dp_cm_v" class="form-check-input" value="30"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="permission_table table-responsive mt_40">
                      <h5>Evaluations</h5>
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">View</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Client List
                              <p class="mb_0 mt_14 font_size_12">(User can see the Client list of his accessed clients in
                                evaluator page, and able to view thier ergo evaluation reports in 'Reports In Review'
                                section)</p>
                            </td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="dp_po" id="dp_po_v" class="form-check-input" value="98"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Reports in Review
                              <p class="mb_0 mt_14 font_size_12">(User can see reports of the client that the he has
                                access to in 'Reports In Review' section if Client List permission is given other wise
                                only of his client)</p>
                            </td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="dp_po" id="dp_po_v" class="form-check-input" value="100"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Start New Evaluation</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="dp_po" id="dp_po_v" class="form-check-input" value="99"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="permission_table table-responsive mt_40">
                      <h5>Reports in Review</h5>
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">Edit Status</th>
                            <th scope="col">View Data Set</th>
                            <th scope="col">Edit Report</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>In Review</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="irs_ir" id="irs_ir_es" class="form-check-input" value="52"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="irs_ir" id="irs_ir_vca" class="form-check-input" value="53"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="irs_ir" id="irs_ir_eca" class="form-check-input" value="54"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Re-Review</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="irs_rr" id="irs_rr_es" class="form-check-input" value="57"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="irs_rr" id="irs_rr_vca" class="form-check-input" value="58"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="irs_rr" id="irs_rr_eca" class="form-check-input" value="59"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Ready for 3rd Party QC</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="irs_rpq" id="irs_rpq_es" class="form-check-input" value="62"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="irs_rpq" id="irs_rpq_vca" class="form-check-input" value="63"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="irs_rpq" id="irs_rpq_eca" class="form-check-input" value="64"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Evaluator Reviewed</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="irs_er" id="irs_er_es" class="form-check-input" value="67"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="irs_er" id="irs_er_vca" class="form-check-input" value="68"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="irs_er" id="irs_er_eca" class="form-check-input" value="69"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Evaluator QC Reviewed</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="irs_eqcr" id="irs_eqcr_es" class="form-check-input"
                                  value="72" v-model="role_data.permission" />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="irs_eqcr" id="irs_eqcr_vca" class="form-check-input"
                                  value="73" v-model="role_data.permission" />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="irs_eqcr" id="irs_eqcr_eca" class="form-check-input"
                                  value="74" v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Finalized</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="irs_fn" id="irs_fn_es" class="form-check-input" value="77"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="irs_fn" id="irs_fn_vca" class="form-check-input" value="78"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="irs_fn" id="irs_fn_eca" class="form-check-input" value="79"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Hidden</td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_fn"
                                  id="irs_fn_es"
                                  class="form-check-input"
                                  value="103"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_fn"
                                  id="irs_fn_vca"
                                  class="form-check-input"
                                  value="104"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_fn"
                                  id="irs_fn_eca"
                                  class="form-check-input"
                                  value="105"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Declined</td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_fn"
                                  id="irs_fn_es"
                                  class="form-check-input"
                                  value="106"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_fn"
                                  id="irs_fn_vca"
                                  class="form-check-input"
                                  value="107"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_fn"
                                  id="irs_fn_eca"
                                  class="form-check-input"
                                  value="108"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Pending Client Approval</td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_fn"
                                  id="irs_fn_es"
                                  class="form-check-input"
                                  value="109"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_fn"
                                  id="irs_fn_vca"
                                  class="form-check-input"
                                  value="110"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_fn"
                                  id="irs_fn_eca"
                                  class="form-check-input"
                                  value="111"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <!-- <div class="permission_table table-responsive mt_40">
                      <h5>Dashboard Permissions</h5>
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">On</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>View Employee Feedback/Comments</td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="dp_vef"
                                  id="dp_vef_o"
                                  class="form-check-input"
                                  value="33"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>View Pending Evaluation Requests</td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="dp_vpe"
                                  id="dp_vpe_o"
                                  class="form-check-input"
                                  value="34"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Contact Support</td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="dp_cs"
                                  id="dp_cs_o"
                                  class="form-check-input"
                                  value="35"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div> -->

                    <!-- <div class="permission_table table-responsive mt_40">
                      <h5>Evaluations</h5>
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">View By Client Access</th>
                            <th scope="col">Edit By Client Access</th>
                            <th scope="col">View Activity By User/Sub User</th>
                            <th scope="col">
                              Edit Activitites By User/Sub User
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Start a New Evaluation</td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="ev_sne"
                                  id="ev_sne_vca"
                                  class="form-check-input"
                                  value="36"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="ev_sne"
                                  id="ev_sne_eca"
                                  class="form-check-input"
                                  value="37"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="ev_sne"
                                  id="ev_sne_vu"
                                  class="form-check-input"
                                  value="38"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="ev_sne"
                                  id="ev_sne_eu"
                                  class="form-check-input"
                                  value="39"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Schedule Evaluation</td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="ev_se"
                                  id="ev_se_vca"
                                  class="form-check-input"
                                  value="40"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="ev_se"
                                  id="ev_se_eca"
                                  class="form-check-input"
                                  value="41"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="ev_se"
                                  id="ev_se_vu"
                                  class="form-check-input"
                                  value="42"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="ev_se"
                                  id="ev_se_eu"
                                  class="form-check-input"
                                  value="43"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Edit Finalized Evalutions</td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="ev_efe"
                                  id="ev_efe_vca"
                                  class="form-check-input"
                                  value="44"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="ev_efe"
                                  id="ev_efe_eca"
                                  class="form-check-input"
                                  value="45"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="ev_efe"
                                  id="ev_efe_vu"
                                  class="form-check-input"
                                  value="46"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="ev_efe"
                                  id="ev_efe_eu"
                                  class="form-check-input"
                                  value="47"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Edit Evaluations</td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="ev_ee"
                                  id="ev_ee_vca"
                                  class="form-check-input"
                                  value="48"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="ev_ee"
                                  id="ev_ee_eca"
                                  class="form-check-input"
                                  value="49"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="ev_ee"
                                  id="ev_ee_vu"
                                  class="form-check-input"
                                  value="50"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="ev_ee"
                                  id="ev_ee_eu"
                                  class="form-check-input"
                                  value="51"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div> -->

                    <!-- <div class="permission_table table-responsive mt_40">
                      <h5>In Review Status</h5>
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">Edit Status</th>
                            <th scope="col">View By Client Access</th>
                            <th scope="col">Edit By Client Access</th>
                            <th scope="col">
                              View By What User/Sub User Has Done
                            </th>
                            <th scope="col">
                              Edit By What User/Sub User Has Done
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>In Review</td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_ir"
                                  id="irs_ir_es"
                                  class="form-check-input"
                                  value="52"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_ir"
                                  id="irs_ir_vca"
                                  class="form-check-input"
                                  value="53"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_ir"
                                  id="irs_ir_eca"
                                  class="form-check-input"
                                  value="54"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_ir"
                                  id="irs_ir_vuhd"
                                  class="form-check-input"
                                  value="55"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_ir"
                                  id="irs_ir_euhd"
                                  class="form-check-input"
                                  value="56"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Re-Review</td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_rr"
                                  id="irs_rr_es"
                                  class="form-check-input"
                                  value="57"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_rr"
                                  id="irs_rr_vca"
                                  class="form-check-input"
                                  value="58"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_rr"
                                  id="irs_rr_eca"
                                  class="form-check-input"
                                  value="59"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_rr"
                                  id="irs_rr_vuhd"
                                  class="form-check-input"
                                  value="60"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_rr"
                                  id="irs_rr_euhd"
                                  class="form-check-input"
                                  value="61"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Ready for 3rd Party QC</td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_rpq"
                                  id="irs_rpq_es"
                                  class="form-check-input"
                                  value="62"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_rpq"
                                  id="irs_rpq_vca"
                                  class="form-check-input"
                                  value="63"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_rpq"
                                  id="irs_rpq_eca"
                                  class="form-check-input"
                                  value="64"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_rpq"
                                  id="irs_rpq_vuhd"
                                  class="form-check-input"
                                  value="65"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_rpq"
                                  id="irs_rpq_euhd"
                                  class="form-check-input"
                                  value="66"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Evaluator Reviewed</td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_er"
                                  id="irs_er_es"
                                  class="form-check-input"
                                  value="67"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_er"
                                  id="irs_er_vca"
                                  class="form-check-input"
                                  value="68"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_er"
                                  id="irs_er_eca"
                                  class="form-check-input"
                                  value="69"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_er"
                                  id="irs_er_vuhd"
                                  class="form-check-input"
                                  value="70"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_er"
                                  id="irs_er_euhd"
                                  class="form-check-input"
                                  value="71"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Evaluator QC Reviewed</td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_eqcr"
                                  id="irs_eqcr_es"
                                  class="form-check-input"
                                  value="72"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_eqcr"
                                  id="irs_eqcr_vca"
                                  class="form-check-input"
                                  value="73"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_eqcr"
                                  id="irs_eqcr_eca"
                                  class="form-check-input"
                                  value="74"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_eqcr"
                                  id="irs_eqcr_vuhd"
                                  class="form-check-input"
                                  value="75"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_eqcr"
                                  id="irs_eqcr_euhd"
                                  class="form-check-input"
                                  value="76"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Finalized</td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_fn"
                                  id="irs_fn_es"
                                  class="form-check-input"
                                  value="77"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_fn"
                                  id="irs_fn_vca"
                                  class="form-check-input"
                                  value="78"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_fn"
                                  id="irs_fn_eca"
                                  class="form-check-input"
                                  value="79"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_fn"
                                  id="irs_fn_vuhd"
                                  class="form-check-input"
                                  value="80"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="irs_fn"
                                  id="irs_fn_euhd"
                                  class="form-check-input"
                                  value="81"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div> -->

                    <!-- <div class="permission_table table-responsive mt_40">
                      <h5>Data</h5>
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">View By Region</th>
                            <th scope="col">View By Client Access</th>
                            <th scope="col">View By Sub User Access</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Full Data Access</td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="data_fda"
                                  id="data_fda_vr"
                                  class="form-check-input"
                                  value="82"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="data_fda"
                                  id="data_fda_vca"
                                  class="form-check-input"
                                  value="83"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="data_fda"
                                  id="data_fda_vsua"
                                  class="form-check-input"
                                  value="84"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Only Summary Report Access</td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="data_osra"
                                  id="data_osra_vr"
                                  class="form-check-input"
                                  value="85"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="data_osra"
                                  id="data_fda_vca"
                                  class="form-check-input"
                                  value="86"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="data_osra"
                                  id="data_fda_vsua"
                                  class="form-check-input"
                                  value="87"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div> -->

                    <!-- <div class="permission_table table-responsive mt_40">
                      <h5>Security</h5>
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">On</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Get Alert For Instrusion</td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="se_gai"
                                  id="se_gai_o"
                                  class="form-check-input"
                                  value="88"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Get Alert For Unauthorized Logins</td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="se_gaul"
                                  id="se_gaul_o"
                                  class="form-check-input"
                                  value="89"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Get Alert For Unusual Logins</td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="se_gauls"
                                  id="se_gauls_o"
                                  class="form-check-input"
                                  value="90"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Get Alert For Firewall</td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="se_gaf"
                                  id="se_gaf_o"
                                  class="form-check-input"
                                  value="91"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Get Alert For Permission Changes</td>
                            <td>
                              <div class="form-check">
                                <input
                                  type="checkbox"
                                  name="se_gapc"
                                  id="se_gapc_o"
                                  class="form-check-input"
                                  value="92"
                                  v-model="role_data.permission"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div> -->

                    <div class="permission_table table-responsive mt_40">
                      <h5>Site</h5>
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">View</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Ergo-Ally Site</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="site_eas" id="site_eas_v" class="form-check-input" value="93"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Video Page Only</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="site_vpo" id="site_vpo_v" class="form-check-input" value="94"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Access Admin Center</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="site_aac" id="site_aac_v" class="form-check-input" value="95"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Blog Admin Center</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="site_aac" id="site_aac_v" class="form-check-input" value="96"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Switch Client</td>
                            <td>
                              <div class="form-check">
                                <input type="checkbox" name="sc" id="sc" class="form-check-input" value="101"
                                  v-model="role_data.permission" />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mt_24 d-flex">
              <div v-show="edit_permission">
                <button v-if="buttonUpdate" type="submit" class="btn btn_primary">
                  Update
                </button>
                <button v-else type="submit" class="btn btn_primary">
                  Save
                </button>
              </div>
              <router-link :to="{ name: 'all_user_group' }">
                <button href="javascript:void(0);" class="btn btn_outline ml_24">
                  Cancel
                </button>
              </router-link>
            </div>
          </div>
        </form>
      </div>
    </main>
  </div>
</template>
<script>
import "vue-select/dist/vue-select.css";
import $ from "jquery";
import commonFunction from "../../mixin/commonFunction";
export default {
  name: "Add_Permission",
  mixins: [commonFunction],
  data() {
    return {
      buttonUpdate: false,
      role_data: {
        id: "",
        u_id: "",
        display_name: "",
        description: "",
        permission: [],
      },
      edit_permission: false,
      permission_error: false,
    };
  },
  mounted() {
    document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
  },
  beforeMount() {
    if (this.$route.params.id != undefined) {
      this.buttonUpdate = true;
      this.getSinglePermission(this.$route.params.id);
    }
    // this.$parent.$refs.fullpageloader.is_full_page_loader = true;
    if (localStorage.getItem("userToken")) {
      // Store for check permission
      let res = this.$store.dispatch("auth/getAuthUser");
      res
        .then((value) => {
          let allPermission = value.user.roles.all_permission;
          if (allPermission.includes("role-edit")) {
            this.edit_permission = true;
          } else if (allPermission.includes("role-view")) {
            this.edit_permission = false;
            $(":input").prop("disabled", true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.tokenExpired();
    }

  },
  methods: {
    addPermission() {
      if (localStorage.getItem("userToken")) {
        var _this = this;
        _this.$validator.validateAll("addPermissionForm").then((isValid) => {
          if (isValid) {
            _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
            if (this.role_data.permission.length < 1) {
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
              this.permission_error = true;
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
              return false;
            } else {
              this.permission_error = false;
            }
            if (_this.role_data.u_id) {
              // alert(JSON.stringify(_this.role_data))
              let config = {
                method: "put",
                url:
                  process.env.VUE_APP_API_URL +
                  "/api/roles/" +
                  _this.role_data.u_id,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${window.localStorage.getItem(
                    "userToken"
                  )}`,
                },
                data: this.encodeAPIRequestWithImages(_this.role_data),
                // data: _this.role_data,
              };
              _this
                .axios(config)
                .then(({ data }) => {
                  _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                  if (data.success) {
                    _this.$toast.success(data.message, {
                      position: "top-right",
                      duration: 5000,
                    });
                    _this.$router.push({ name: "all_user_group" });
                  }
                  else {
                    _this.$toast.error(data.message, {
                      position: "top-right",
                      duration: 5000,
                    });
                    _this.$router.push({
                      name: "all_user_group",
                    });
                  }
                })
                .catch((err) => {
                  _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                  if (err.response.status == 422) {
                    var err1 = '';
                    for (let x in err.response.data.error) {
                      err1 += err.response.data.error[x];
                      err1 += '<br>';
                    }
                    this.$toast.error(err1, {
                      position: "top-right",
                      duration: 5000,
                    });
                  } else if (err.response.status == 401) {
                    this.tokenExpired();
                  } else {
                    this.$toast.error(err.response.data.error, {
                      position: "top-right",
                      duration: 5000,
                    });
                    _this.$router.push({
                      name: "all_user_group",
                    });
                  }
                });
            } else {
              let config = {
                method: "post",
                url: process.env.VUE_APP_API_URL + "/api/roles",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${window.localStorage.getItem(
                    "userToken"
                  )}`,
                },
                data: this.encodeAPIRequestWithImages(_this.role_data),
                // data: _this.role_data,
              };
              _this
                .axios(config)
                .then(({ data }) => {
                  _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                  _this.$toast.success(data.message, {
                    position: "top-right",
                    duration: 5000,
                  });
                  _this.$router.push({ name: "all_user_group" });
                })
                .catch(({ response }) => {
                  _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                  if (response.status == 422) {
                    var err1 = '';
                    for (let x in response.data.error) {
                      err1 += response.data.error[x];
                      err1 += '<br>';
                    }
                    this.$toast.error(err1, {
                      position: "top-right",
                      duration: 5000,
                    });
                  } else if (response.status == 401) {
                    this.tokenExpired();
                  } else {
                    this.$toast.error(response.data.error, {
                      position: "top-right",
                      duration: 5000,
                    });
                  }
                });
            }
          } else {
            console.log("Error In Validation", _this.errors);
          }
        });
      } else {
        this.tokenExpired();
      }
    },
    getSinglePermission(id) {
      var _this = this;
      let config = {
        method: "get",
        url: process.env.VUE_APP_API_URL + "/api/roles/" + id + "/edit",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          if (data.success) {
            var decodedJson = _this.decodeAPIResponse(data.data);
            _this.role_data.id = decodedJson.records.id;
            _this.role_data.u_id = decodedJson.records.u_id;
            _this.role_data.display_name = decodedJson.records.display_name;
            _this.role_data.description = decodedJson.records.description;
            _this.role_data.permission = decodedJson.rolePermissions;
          }
          else {
            _this.$toast.error(data.message, {
              position: "top-right",
              duration: 5000,
            });
            _this.$router.push({
              name: "all_user_group",
            });
          }
        })
        .catch(({ response }) => {
          if (response.status == 403) {
            _this.$router.push({ name: "403" });
          } else {
            _this.$router.push({
              name: "all_user_group",
              params: { errorMsg: response.data.error }
            });
          }

          /*if(response.data.error){
            _this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
          }*/
        });
    },
  },
};
</script>
